import {
  Button,
  Heading,
  Text,
  useBreakpointValue,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import * as React from "react";

// styles
const pageStyles = {
  color: "#232129",
  paddingTop: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  backgroundColor: "#050505",
  height: "100vh",
  margin: "0",
  padding: "0",
};

// markup
const IndexPage = () => {
  const [isLargerThan550] = useMediaQuery("(min-width: 550px)");

  return (
    <main
      style={{
        backgroundColor: "#050505",
        height: "100vh",
        paddingLeft: "40px",
        paddingRight: "40px",
      }}
    >
      <title>Home Page</title>
      <Heading
        pt={isLargerThan550 ? "60" : "32"}
        size={isLargerThan550 ? "4xl" : "3xl"}
        color="#BEE3F4"
        textAlign={isLargerThan550 ? "center" : "left"}
      >
        Click for Code
      </Heading>
      <Text
        marginTop="2"
        fontSize="2xl"
        color="white"
        textAlign={isLargerThan550 ? "center" : "left"}
      >
        We're a web development agency based in India
      </Text>
      <VStack mt="16">
        <Button
          color="white"
          _hover={{ color: "#050505", backgroundColor: "white" }}
          variant="outline"
          size="lg"
          as="a"
          href="mailto:clickforcode@gmail.com"
          isFullWidth={!isLargerThan550}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Contact&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </Button>
      </VStack>
    </main>
  );
};

export default IndexPage;
